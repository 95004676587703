@page {
  margin: 0;
}

@media print {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
   
  }
}


html, body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;

}
/* Invoice */
.invoice {
  margin:0;
  border:none
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 80px;  /* Adjust width as necessary */
  height: auto;
}

.order-number {
  font-size: 24px;
  font-weight: bold;
}

.vendor {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.items {
  display: block; 
}

.order-items{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
}

.item-name {
  width:40%;
  letter-spacing: -0.015rem;
}



.price,
.quantity {
  width: 20%;
  color:#000;
}

.item-total {
  text-align: right;
  width: 20%;
  color:#000;
}

.header{
  font-weight: bold!important;
  color:#000;
  font-size: 14px!important;
}

.total {
  text-align: right;
  font-weight: bold;
  font-size: 14px!important;
}

.totaladjustment {
  text-align: right;
  font-weight: bold;
  font-size: 14px!important;
}

.totalhst {
  text-align: right;
  font-weight: bold;
  font-size: 14px!important;
}
.invoice {
  width: 100%; /* Subtract padding */
  padding: 20px;
}
.invoice-wrapper{
  margin:0;
  border:none
}

@media only screen and (max-width: 1100px){

  .invoice{
    margin:0px;
    font-size: 14px!important;
  }

  .item-name {
    width:40%;
  }
  

  
  .price
   {
    text-align: center;
    width: 20%;
  }
  .quantity {
    text-align: center;
    width: 10%;
  }

  .item-total {
    text-align: right;
    width: 30%;
    color:#000;
  }

  
}