.App {
  text-align: center;
}
.app-version{
  position: relative;;
   color: white;
   top: 4px;
   font-size: small;
   /* margin: 0;
   padding: 0; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.card-header {
  background: transparent;
  color: #141515;
  font-weight: 500;
  padding: 12px 16px;
  border-bottom: 1px solid #e1e1e1;
}

.contact-button {
  position: relative;
  background-color: rgb(223, 238, 20);
  font-weight: 500;
  padding: 12px 16px;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 10px;
  top: -30px;
  width: 100px;
 }

 .contact-problem-button {
  position: relative;
  color: white;
  background-color: rgb(219, 90, 14);
  font-weight: 500;
  padding: 12px 16px;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 10px;
  top: -30px;
  width: 85%;
 }


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}